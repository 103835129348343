import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { useState } from "react";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
// const token = checkAuth()

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
    
    // Flag to track whether it's the first hour
    let isFirstHour = true;
  
    // Set up the interval to call a function every hour after the first hour
    const intervalId = setInterval(() => {
      // After the first hour, execute the code
      if (!isFirstHour) {
        localStorage.removeItem("myob-session");
        window.location.reload();
      } else {
        // After the first hour, set isFirstHour to false
        isFirstHour = false;
      }
    }, 3600000); // 3600000 milliseconds = 1 hour
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  

  const [isLoggedIn, setIsloggedIn] = useState(false);

  if (!isLoggedIn) {
    return <Login setLogin={setIsloggedIn} />;
  } else {
    return (
      <>
        <Router >
          <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/documentation" element={<Documentation />} /> */}

            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            <Route path="*" element={<Navigate to={"/app/transactions"} />} />
            {/* <Route path="*" element={<Navigate />} /> */}
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
